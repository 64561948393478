import React, { useState, useEffect, useRef } from 'react';
import { Row, Col, Button, Divider } from 'antd';
import axios from "axios";
import Manual from './Sections/Manual';
import CompoAutomata from './Sections/CompoAutomata';
import CompoOrderList from './Sections/CompoOrderList';
import CompoCompletedTrade from './Sections/CompoCompletedTrade';
import {symbol_list} from '../../../config';

import useInterval from '../../util/useInterval';
import WebWorker from 'react-web-workers';
import timer from './workers/timer';

function LandingPage(props) {
    const [AutoMode, setAutoMode] = useState(false);
    const [SymbolList, setSymbolList] = useState([]);
    const [DefaultSymbolIdx, setDefaultSymbolIdx] = useState(0);
    //const [BnPrice, setBnPrice] = useState(null);              // 현재 bn_price
    const [AutomataReport, setAutomataReport] = useState([]);              // 현재 bn_price
    const [AutomataUSDT, setAutomataUSDT] = useState(0);              // 현재 bn_price

    //const [UpdateAsset, setUpdateAsset] = useState(false);
    const [UpdateOrderList, setUpdateOrderList] = useState(false);
    const [UpdateCompletedTrade, setUpdateCompletedTrade] = useState(false);
    //const [DataAsset, setDataAsset] = useState([]);
    const [DataOrderList, setDataOrderList] = useState([]);
    const [DataCompletedTrade, setDataCompletedTrade] = useState(null);

    const [FailCount, setFailCount] = useState(0);      //데이터를 몇번 연속적으로 못 가져왔는지 표시

    //web worker
    const twref = useRef();
    const [UpdatePeriodic, setUpdatePeriodic] = useState(false);    //true, false가 바뀔 때마다 매번 periodic_get_from_server 실행

    useEffect(()=>{
        console.log('-------------------------------- STARTING TIMER WORKER');
        const [ timerWorker ] = WebWorker([timer]);
        twref.current = timerWorker;
        twref.current.onmessage = function(event) {
            //console.log('timerWorker:', event.data);
            const tbool = ((event.data.count)%2===0);
            setUpdatePeriodic(tbool);
        }
        twref.current.postMessage('start');
    }, []);

    useEffect(()=>{
        periodic_get_from_server(); 
    }, [UpdatePeriodic]);

    //worker를 사용하지 않고 주기적으로 실행할 때
    //useInterval(periodic_get_from_server, 1000);      


    // useEffect(()=>{
    //     if (UpdateAsset)
    //         updateAsset();
    // }, [UpdateAsset]);

    useEffect(()=>{
        if (UpdateOrderList)
            updateOrderList();
    }, [UpdateOrderList]);

    useEffect(()=>{
        if (UpdateCompletedTrade)
            updateCompletedTrade();
    }, [UpdateCompletedTrade]);

    useEffect(()=>{
        getSystemParam();
        //getSymbolList(); 
        updateAutomataReport();
        //updateAsset();
        updateOrderList();
        updateCompletedTrade();
    }, []);

    // 주기적으로 서버에서 데이터를 가져옴
    async function periodic_get_from_server() {
        // automata data는 항상 가져옴
        updateAutomataReport();

        if (FailCount>10) {     // 10번 연속 실패하면 자동 가져오기를 멈춤
            console.log('(FailCount>10) --> Web Worker will be terminated !!!')
            twref.current.terminate();            
            return;
        }

        try {
            // trader는 client로 보낼 정보가 있을 때 que_frontend에 넣어놓는다.
            // client가 getinfo를 GET하는 신호를 보내면, que_frontend의 내용을 보내준다.
            const res = await axios.get("/api/getinfo");
            if (res.data.success===false){
                return;
            }
        
            const reslen = res.data.contents.length;
            for (let k=0; k<reslen; k++){
                const tdata = res.data.contents[k];

                switch (tdata.type) {
                    // case 'plot':    updatePlotData();  break;
                    case 'update':  
                        if (tdata.data==='asset') {
                            console.log('update asset: ??????????????');
                            //setUpdateAsset(true);
                        }
                        else if (tdata.data==='orderlist') {
                            setUpdateOrderList(true);
                        }
                        else if (tdata.data==='completed_list') {
                            setUpdateCompletedTrade(true);
                        }
                        else {
                            console.log('unknown data:', tdata.data);
                        }
                        break;
                    case 'automata_report':
                        console.log('setAutomataReport=', tdata.data.contents);
                        setAutomataReport(tdata.data.contents);
                        break;

                    // case 'bnbusdt':
                    //     setBNBUSDT(tdata.data);
                    //     break;
        
                    // case 'proba_posi':
                    //     setProbaPosi(tdata.data);
                    //     break;

                    // case 'proba_nega':
                    //     setProbaNega(tdata.data);
                    //     break;
        
                    case 'alert':
                        alert(tdata.data);
                        break;

                    default:    alert('cannot parsing: '+ tdata.type);
                }
            }
            setFailCount(0);
        } catch (err) {
            console.log('get_info:ERROR:', err.message);
            setFailCount(FailCount+1);
        }

    }

 
    ////////////////////////// update functions /////////////////////////////
    async function getSystemParam() {
        const res = await axios.get("/api/get_system_params");
        console.log('get_system_params=', res.data);
        if (res.data.success){
            setAutoMode(res.data.contents.automode);
            setSymbolList(res.data.contents.symbol_list);
        }
    }

    // async function updateAsset() {
    //     const res = await axios.get("/api/get_asset");
    //     console.log('get_asset=', res.data.contents);
    //     if (res.data.success){
    //         setDataAsset(res.data.contents);
    //         setUpdateAsset(false);
    //     }
    // }

    // server의 context.orderlist를 받아옴
    async function updateOrderList() {
        const res = await axios.get("/api/get_orderlist");
        console.log('get_orderlist=', res.data);
        if (res.data.success) {
            setDataOrderList(res.data.contents);
            setUpdateOrderList(false);
        }
    }

    async function updateCompletedTrade() {
        const dataToCommit = {limit: 40};
        const res = await axios.post("/api/get_completed_list", dataToCommit);
        console.log('get_completed_list=', res.data);
        if (res.data.success){
            setDataCompletedTrade(res.data.contents);
            setUpdateCompletedTrade(false);
        }
    }

    async function updateAutomataReport() {
        const res = await axios.get("/api/get_automata_report");
        //console.log('get_automata_report=', res.data);
        if (res.data.success){
            setAutomataReport(res.data.contents.items);
            setAutomataUSDT(res.data.contents.usdt);
            // setBNBUSDT(res.data.contents.bnbusdt);
            // setProbaPosi(res.data.contents.proba_posi);
            // setProbaNega(res.data.contents.proba_nega);
        }
    }

    ///////////////// debug
    function onClickUpdate() {
        //setUpdateAsset(true);
        setUpdateOrderList(true);
        setUpdateCompletedTrade(true);
    }


    return (
        <div>
            <hr/>
            <Row justify="space-around">
                <Col sm={24} md={8}>
                    <Button onClick={onClickUpdate} style={{margin:'10px'}}> Update All</Button>
                    <Manual DefaultSymbol={SymbolList[DefaultSymbolIdx]} AutoMode={AutoMode} setAutoMode={setAutoMode}/>
                    <br/><hr/>
                    <CompoAutomata AutomataReport={AutomataReport} AutomataUSDT={AutomataUSDT} SymbolList={SymbolList}/>
                </Col>
            </Row>
            <hr/>
            <Row justify='space-around'>
                <Col span={22}>
                    <CompoOrderList OrderList={DataOrderList}/>
                </Col>
            </Row>
            <hr/>
            <Row justify='space-around'>
            <Col span={22}>
                    <CompoCompletedTrade completedList={DataCompletedTrade}/>
                </Col>
            </Row>
        </div>
    )
}

export default LandingPage
