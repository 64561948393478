import React, { useState, useEffect, useRef } from 'react';
import { Row, Col, Button, Divider } from 'antd';
import axios from "axios";

function AdminPage(props) {
    const [Config, setConfig] = useState([]);
    const [ServerStatus, setServerStatus] = useState('');

    useEffect(()=>{
        get_admin_value(); 
    }, []);

    async function get_admin_value() {
        await get_algorithm_params();
        await get_server_status();
    }

    async function get_algorithm_params() {
        const res = await axios.post("/api/get_algorithm_params",{'id':'ghlee'});
        console.log('get_algorithm_params--->', res.data);
        if (res.data.success){            
            setConfig(res.data.contents);
        }
    }

    async function get_server_status() {
        const res = await axios.post("/api/get_server_status",{'id':'ghlee'});
        console.log('get_server_status--->', res.data);
        if (res.data.success){            
            setServerStatus(res.data.contents);
        }
    }

    async function handleValueChange(index, newValue) {
        const newConfig = [...Config];
        newConfig[index].value = newValue;

        const dataToSend = {
            id : 'ghlee',
            name: Config[index].name,
            value: newValue
        }
        const res = await axios.post("/api/set_algorithm_params", dataToSend);
        console.log('set_algorithm_params--->', res.data);
        if (res.data.success){            
            setConfig(res.data.contents);
            //setConfig(newConfig);
        }        
    }

    const render =  Config && Config.map( function (keyval, index) {
        console.log('keyval=', keyval);

        return (
            <tr key={index}>
                <td>{keyval.name}</td>
                <EditableCell
                    value={keyval.value}
                    onValueChange={(newValue) => handleValueChange(index, newValue)}
                />
            </tr>
            )
    }
)

return (
    <div>
        <Row justify='center'>
            <Col>
                <table className='mytable'>
                    <thead>
                        <tr>
                            <th>Name</th>
                            <th>Value</th>
                        </tr>
                    </thead>
                    <tbody>
                        {render}
                    </tbody>
                </table>
            </Col>
        </Row>
        <Row>
            Server Status: {ServerStatus}
        </Row>
    </div>
)
}

function EditableCell({ value, onValueChange }) {
    const [editMode, setEditMode] = useState(false);
    const [inputValue, setInputValue] = useState(value);

    useEffect(() => {
        setInputValue(value);
    }, [value]);

    const handleDoubleClick = () => {
        setEditMode(true);
    };

    const handleChange = (event) => {
        setInputValue(event.target.value);
    };

    const handleBlur = () => {
        setEditMode(false);
        onValueChange(inputValue);
    };

    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            setEditMode(false);
            onValueChange(inputValue);
        }
    };

    return editMode ? (
        <td>
            <input
                type="text"
                value={inputValue}
                onChange={handleChange}
                onBlur={handleBlur}
                onKeyDown={handleKeyDown}
                autoFocus
            />
        </td>
    ) : (
        <td onDoubleClick={handleDoubleClick}>
            {value}
        </td>
    );
}

export default AdminPage
