import React, { useState, useEffect, useRef } from 'react';
import axios from "axios";
import UplotReact from 'uplot-react';
import 'uplot/dist/uPlot.min.css';

function AssetPage(props) {
    const maxListLen = 100;
    const [PlotDataXY, setPlotDataXY] = useState(false);

    useEffect(()=>{
        updateAssetList(); 
    }, []);

    async function updateAssetList() {
        const dataToSubmit = {
            limit: 20
        }
        const res = await axios.post("/api/get_asset_list", dataToSubmit);
        if (res.data.success){
            console.log('get_asset_list=', res.data);

            //make xy data
            const resdata=res.data;
            const tlen = resdata.length;
            const time = new Array(tlen);
            const usdt = new Array(tlen);
            for (let k=0; k<tlen; k++) {
                time[k] = k;
                usdt[k] = resdata[k].usdt;
            }
    
            setPlotDataXY([time, usdt]);
        }
    }

    ///////////////////////// UPLOT ///////////////////////////////
    const options= {
		title: "Chart",
		width: 400,
		height: 300,
		series: [
			{
    			label: "Date"
			},
			{
                label: "bn",
                points: { show: false },
                stroke: "blue",
                //fill: "blue"
            }            
            ],
		scales: { x: { time: false } },
		//plugins: [dummyPlugin()]
	};

    return (
        <div>
            <UplotReact
                options={options}
                data={PlotDataXY}
                // target={target}
                onCreate={(chart) => {}}
                onDelete={(chart) => {}}
            />
        </div>
    )
}

export default AssetPage
