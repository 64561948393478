const PORT = 5000;
//const symbol_list = ['BTCUSDT', 'ETHUSDT'];
const REACT_APP_GOOGLE_CLIENT_ID="434999881954-00eph6u0rse4nrhsvndoepu7d6946fk7.apps.googleusercontent.com";


module.exports = {
    PORT,
    REACT_APP_GOOGLE_CLIENT_ID,
    //symbol_list
}
