import React, { useState } from 'react';
import RightMenu from './RightMenu';
import { Row, Col } from 'antd';
import './Navbar.css';
const Logo = require('../../../assets/images/cherry_pic.png');

function NavBar() {

    return (
        // <nav className="menu" style={{ position: 'fixed', zIndex: 1, width: '100%' }}>
        //     <div className="menu__logo">
        //         <a href="/"><img src={Logo} alt="Logo" style={{ width: '100%', marginTop: '-5px' }} /></a>
        //     </div>

        //     <div className="menu__container">
        //         <RightMenu mode="horizontal" />
        //     </div>
        // </nav>

        <nav className="menu">
            <div className="menu-inner">
                <div className="container">
                    <Row justify="space-between " className="header-row" align="center">
                        <Col span={2}>
                            <img src={Logo} height={50} alt="Logo" className="logo" /> 
                        </Col>
                        <Col span={12}>
                            <font size={6}> Cherry System </font>
                        </Col>
                        <Col span={10}>
                            <RightMenu/>
                        </Col>
                    </Row>
                </div>
            </div>
        </nav>
    )
}

export default NavBar