import React from 'react';
import {BrowserRouter as Router, Switch, Route, Link} from "react-router-dom";
import LandingPage from './components/views/LandingPage/LandingPage'
import NavBar from './components/views/NavBar/NavBar'
import LoginPage from './components/views/LoginPage/LoginPage';
import AssetPage from './components/views/AssetPage/AssetPage';
import AdminPage from './components/views/AdminPage/AdminPage';
import Auth from './hoc/auth'

function App() {
  return (
	<div>
	<Router>
		<div>
		<NavBar/>
		<Switch>
			<Route exact path="/" component={Auth(LandingPage, true )  } />
			<Route exact path="/login" component={Auth(LoginPage, false) } />
			<Route exact path="/assetlist" component={Auth(AssetPage, true) } />
			<Route exact path="/admin" component={Auth(AdminPage, true) } />
		</Switch>
		</div>
	</Router>
	</div>
  );
}

export default App;

