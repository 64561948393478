import React, { useState, useEffect, useRef } from 'react';
//import { symbol_list } from '../../../../config';


function CompoAutomata({AutomataReport, AutomataUSDT, SymbolList}) {


    const render = AutomataReport && AutomataReport.map(
        function (report, index) {
            //console.log('report=', report);
            //console.log('index=', index);
            
            const symbol = SymbolList[index];
            const diff = report['diff'];
            const curr = report['curr'];
            const posusd = report['posusd'];
            const gain_percent = report['gain_percent'];
            const cnt = report['cnt'];

            return (
                <tr key={index}>
                    <td>{symbol}</td>
                    <td>{curr.toFixed(4)}</td>
                    <td>{diff.toFixed(3)}</td>
                    <td>{cnt}</td>
                    <td>{posusd.toFixed(2)}</td>
                    <td>{gain_percent.toFixed(2)}</td>
                </tr>
            );    

        }
    );

    return (
        <div style={{margin:'10px'}}>
            <table>
                <thead>
                    <tr>
                        <th> Symbol </th>
                        <th> Curr </th>
                        <th> Diff </th>
                        <th> Count</th>
                        <th> USD 평가액 </th>
                        <th> Gain(%) </th>
                    </tr>
                </thead>
                <tbody>
                    {render}
                    <tr>
                        <td>Total</td>
                        <td>-</td>
                        <td>-</td>
                        <td>-</td>
                        <td>{AutomataUSDT.toFixed(1)}</td>
                        <td>-</td>
                    </tr>

                </tbody>
            </table>
        </div>
    )
}

export default CompoAutomata
