const count = () => {
  let count = 0;
  let mtimer;

  const sentData = () => {
    // eslint-disable-next-line no-restricted-globals
    self.postMessage({ count })
  }

  // eslint-disable-next-line no-restricted-globals
  self.onmessage = (event) => {
    if (event.data==='start') {
      mtimer = setInterval(()=>{
        sentData();
        count+=1;
      }, 1000);
    } else {
      clearInterval(mtimer);
    }
  }
}

export default count;
