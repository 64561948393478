import React, {useState, useEffect} from 'react';
import { Row, Col, Menu } from 'antd';
import { withRouter, Link } from 'react-router-dom';
import axios from "axios";
import { useSelector } from "react-redux";

function RightMenu(props) {
	const user = useSelector((state) => state.user);
	const [Login_Logout, setLogin_Logout] = useState('');

	useEffect(()=>{
		const tmp = (user.userData && user.userData.isAuth)? 'SignOut':'SignIn';
		setLogin_Logout(tmp);
    }, [user.userData]);

	async function onClickLogout() {
		if (Login_Logout==='SignIn')
			return;
		
        const res = await axios.get("/api/users/logoutgoogle");

		if (res.data.success) {
			alert('logout success');
			props.history.push(`/login`);
		}
	}

	//const Login_Logout = (user.userData && user.userData.isAuth)? 'SignOut':'SignIn';
	//const Login_Logout = 'SignOut';

	const items = [
		{ label: (<a href="/">Home</a>), key: 'home' },
		{ label: (<a href="/assetlist">Asset List</a>), key: 'assetlist' },
		{ label: (<a href="/admin">Admin</a>), key: 'admin' },
		{ label: (<Link to="/" onClick={onClickLogout}>{Login_Logout}</Link>), key: 'logout' }
	];

	return (
		<Menu items={items} mode='horizontal'/>
	)
}

export default withRouter(RightMenu);

