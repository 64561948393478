import React, { useState, useEffect, useRef } from 'react';
import { Row, Col, Button, Input, Radio, Modal } from 'antd';
import axios from "axios";
import { Switch } from 'antd';

function Manual(props) {

    const [ModalOpen, setModalOpen] = useState(false);
    //const [BuySellMode, setBuySellMode] = useState('');
    const [ManualOrderQty, setManualOrderQty] = useState(0.002);
    const [ManualOrderPrice, setManualOrderPrice] = useState(0);
    const [ManualOrderSign, setManualOrderSign] = useState(1);
    const [ManualOrderSymbol, setManualOrderSymbol] = useState('IOTAUSDT');
    // const [AutoMode, setAutoMode] = useState(false);

    async function onClickOrder(order_str){
        console.log('order_str=', order_str);
        const dataToSubmit = {
            order_str : order_str,
            order_market : props.DefaultSymbol
        }
        const res = await axios.post('/api/manual_order', dataToSubmit);
        if (res.data.success===false){
            alert('Error: onClickOrder')
        }
    }

    // async function onClickManualTrade(){
    //     setBuySellMode(order_str);
    //     setModalOpen(true);
    // }

    async function onOKTrade() {
        setModalOpen(false);
        const dataToSubmit = {
            order_str : (ManualOrderSign>0)? 'buy':'sell',
            order_market : ManualOrderSymbol,
            order_qty: parseFloat(ManualOrderQty),
            order_price: parseFloat(ManualOrderPrice)
        }
        const res = await axios.post('/api/manual_order', dataToSubmit);
        if (res.data.success===false){
            alert('Error: onClickOrder')
        }
    }

    async function onCancelTrade() {
        setModalOpen(false);
    }

    //////////////// manual mode switch //////////////////
    async function onChangeManual() {
        let order_str;
        if (props.AutoMode===true) {
            order_str = 'manual_mode';
        } else {
            order_str = 'auto_mode';
        }
        const dataToSubmit = {
            order_str : order_str,
            order_market : '',
            order_qty: 0,
            order_price: 0
        }
        const res = await axios.post('/api/manual_order', dataToSubmit);
        if (res.data.success===false){
            alert('Error: onClickOrder')
            return;
        }

        props.setAutoMode(!props.AutoMode);
    }
    

    return (
        <div>
            <Row justify='center' align='middle'>
                <Switch checked={props.AutoMode} onChange={onChangeManual} checkedChildren="AutoMode" unCheckedChildren="AutoMode"/>
                <Button type='primary' onClick={()=>setModalOpen(true)} style={{margin:'10px'}}>Manual Trade</Button> <br/>
            </Row>
            {/* <Row justify='space-around'>
                <Col span={6}>
                    <Button type='primary' onClick={()=>onClickOrder('clear_all_position')} style={{margin:'10px'}}>Clear All Position</Button> <br/>
                </Col>
                <Col span={6}>
                    <Button type='primary' onClick={()=>onClickOrder('update_price')} style={{margin:'10px'}}>Update Price</Button> <br/>
                </Col>
            </Row> */}
            <Modal
                title       ='Manual Trade'
                open        ={ModalOpen}
                onOk        ={onOKTrade}
                onCancel    ={onCancelTrade}
                width       ={400}
                destroyOnClose={true}
            >
                Symbol: <Input value={ManualOrderSymbol} onChange={e=>setManualOrderSymbol(e.target.value)}/>
                Price: <Input value={ManualOrderPrice} onChange={e=>setManualOrderPrice(e.target.value)}/>
                Quantity: <Input value={ManualOrderQty} onChange={e=>setManualOrderQty(e.target.value)}/>
                <Row>
                    <Radio.Group onChange={e=>setManualOrderSign(e.target.value)} value={ManualOrderSign}>
                        <Radio value={1}>Buy</Radio>
                        <Radio value={-1}>Sell</Radio>
                    </Radio.Group>
                </Row>
                USD: ${ManualOrderPrice*ManualOrderQty}
            </Modal>

        </div>
    )
}

export default Manual
